import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import {externalLinks, pagesLinks, pagesLinksKz} from "./links"
import CoffeeIcon from "../../../components/_V2/BusinessTypes/assets/svg/CoffeeIcon"
import CafeIcon from "../../../components/_V2/BusinessTypes/assets/svg/CafeIcon"
import RestaurantIcon from "../../../components/_V2/BusinessTypes/assets/svg/RestaurantIcon"
import componentStyles from "../../../components/_V2/BusinessTypes/styles.module.scss"
import {
	BUBBLES_TYPES,
	BUSINESS_TYPES,
	Data,
} from "../../../components/_V2/BusinessTypes/types"
import BarIcon from "../../../components/_V2/BusinessTypes/assets/svg/BarIcon"
import FoodtrackIcon from "../../../components/_V2/BusinessTypes/assets/svg/FoodtrackIcon"
import MultiformatIcon from "../../../components/_V2/BusinessTypes/assets/svg/MultiformatIcon"
import classNames from "classnames"
import { Locales } from "../../../localization/types";

export const getBusinessTypes = (locale: Locales): Data => {
	if (locale === 'ru-KZ') {
		return {
			title: "Преодолей барьеры и открой возможности",
			titleClass: componentStyles.title__kz,
			subTitle: "создан для всех типов бизнеса",
			readMore: "Смотреть интервью",
			theme: "blueSky",
			businessType: [
				{
					type: BUSINESS_TYPES.COFFEE_SHOP,
					tabName: pagesLinks.businessTypes.coffeehouse.text,
					icon: CoffeeIcon,
					name: "Рауан Омар, \nоснователь кофейни «Korizza»",
					nameClass: componentStyles.businessName__kz,
					details:
						"Используем инструменты Quick Resto для оптимизации \nпроцессов и анализа продаж, чтобы развивать бизнес.",
					detailsTablet:
						"Используем инструменты Quick Resto для оптимизации процессов и анализа продаж, чтобы развивать бизнес.",
					detailsMobile:
						"Используем инструменты Quick Resto для оптимизации процессов и анализа продаж, чтобы развивать бизнес.",
					photoCaption: "«Девиз: \nКофе объединяет людей»",
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/korizza.png"
							alt="Логотип кофейни «Korizza»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={componentStyles.businessLogo__kz}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/korizza.jpeg"
							alt="Рауан Омар, основатель кофейни «Korizza»"
							// breakpoints={[424, 740, 920, 524]}
							// sizes="(max-width: 500px) 424px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 524px"
							objectFit="cover"
							placeholder="blurred"
							quality={100}
						/>
					),

				},
				{
					type: BUSINESS_TYPES.RESTAURANT,
					tabName: pagesLinks.businessTypes.restaurant.text,
					icon: RestaurantIcon,
					name: "Константин Кудобаев, \nсооснователь частного бара «Сезоны»",
					photoCaption: "«Чувствую себя героем игры, каждый день новые задания»",
					bubbleType: BUBBLES_TYPES.SECONDARY,
					details: (
						<>
							В&nbsp;первую очередь нас привлекло удобство интерфейса&nbsp;&mdash;
							это&nbsp;айфон на&nbsp;рынке систем автоматизации.
						</>
					),
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/seasons.png"
							alt="Логотип ресторана «Сезоны»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo,
								componentStyles.businessMainImageCropSeasons
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/seasons.jpg"
							alt="Константин Кудобаев, сооснователь частного бара «Сезоны»"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					link: {
						href: `${externalLinks.quickrestoRU.href}secret/sezony/`,
						text: "Смотреть интервью",
						useGatsbyLink: false,
						target: '_blank'
					},
				},
				{
					type: BUSINESS_TYPES.BAR,
					tabName: pagesLinks.businessTypes.bar.text,
					icon: BarIcon,
					name:
						"Куаныш Нурахов, \nсооснователь изакая-бар «Tang»",
					photoCaption: (
						<div className={componentStyles.businessPhotoCaptionContent_tertiary}>
							«Среди тех, <br />
							кто любит лапшу <br />
							нет плохих людей!»
						</div>
					),
					bubbleType: BUBBLES_TYPES.LINEAR,
					details: (
						<>
							Quick Resto оптимизирует все процессы и&nbsp;помогает контролировать движение денежных средств
						</>
					),
					detailsMobile: (
						<>
							Используем инструменты Quick Resto для оптимизации процессов и&nbsp;анализа продаж, чтобы развивать бизнес.
						</>
					),
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/tang.png"
							alt="Логотип сети винотек и баров «Первый нос»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo__kz_bar
								// componentStyles.businessMainImageCropBar
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/tang.jpeg"
							alt="Куаныш Нурахов - сооснователь изакая-бар «Tang»"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					type: BUSINESS_TYPES.CAFE,
					tabName: pagesLinks.businessTypes.cafe.text,
					icon: CafeIcon,
					name: "Анастасия и Андрей, \nсооснователи «О, мой Боул!»",
					photoCaption: "«Общепит не только про еду, но и про эмоции»",
					bubbleType: BUBBLES_TYPES.QUATERNARY,
					details: (
						<>
							Мы&nbsp;делегировали сотрудникам все процессы, на&nbsp;которые
							нам&nbsp;раньше требовался отдельный человек.
						</>
					),
					detailsMobile: (
						<>
							Мы&nbsp;делегировали сотрудникам все процессы, на&nbsp;которые нам
							раньше требовался отдельный человек.
						</>
					),
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/oh-my-bowl.png"
							alt="Логотип Oh My Bowl"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo,
								componentStyles.businessMainImageCropOhMyBowl
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/oh-my-bowl.jpg"
							alt="Анастасия и Андрей, сооснователи Oh My Bowl"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					link: {
						href: `${externalLinks.quickrestoRU.href}secret/ohmybowl/`,
						text: "Смотреть интервью",
						useGatsbyLink: false,
						target: '_blank'
					},
				},
				{
					type: BUSINESS_TYPES.FOODTRACK,
					tabName: pagesLinks.businessTypes.foodtruck.text,
					icon: FoodtrackIcon,
					name: "Владимир Иванюгин, \nвладелец фудтрака «Крошка Ли»",
					details:
						"Если у тебя есть планшет, ты скачиваешь программу Quick Resto, заводишь туда позиции и торгуешь. Для этого тебе нужно 2 часа.",
					photoCaption: "«Плюёшь на всё и открываешь свой бизнес»",
					bubbleType: BUBBLES_TYPES.PYATERIC,
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/kroshka-li.png"
							alt="Логотип фудтрака «Крошка Ли»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo,
								componentStyles.businessMainImageCropKroshkaLi
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/kroshka-li.jpg"
							alt="Владимир Иванюгин, владелец фудтрака «Крошка Ли»"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					link: {
						href: `${externalLinks.quickrestoRU.href}secret/kroshka-li/`,
						text: "Смотреть интервью",
						useGatsbyLink: true,
						target: '_blank'
					},
				},
				{
					type: BUSINESS_TYPES.MULTI,
					tabName: pagesLinks.businessTypes.multiformats.text,
					icon: MultiformatIcon,
					name: "Максим Кравцов, \nоснователь велокофейни «Вираж»",
					details: (
						<>
							В&nbsp;кафе в&nbsp;смене работает один человек&nbsp;&mdash; ему нужно
							всё делать быстро и&nbsp;не&nbsp;занимать голову лишними расчётами.
						</>
					),
					photoCaption:
						"«А почему не собирать велосипедистов в собственном \nкафе?»",
					bubbleType: BUBBLES_TYPES.HEX,
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/virag.png"
							alt="Логотип велокофейни «Вираж»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo,
								componentStyles.businessMainImageCropVirag
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/virag.jpg"
							alt="Максим Кравцов, основатель велокофейни «Вираж»"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					link: {
						href: `${externalLinks.quickrestoRU.href}secret/virazh/`,
						text: "Смотреть интервью",
						useGatsbyLink: false,
						target: '_blank'
					},
				},
			],
		}
	}

	if (locale === 'kz-KZ') {
		return {
			title: "Тосқауылдардан өтіңіз және мүмкіндіктерді ашыңыз",
			titleClass: componentStyles.title__kz,
			subTitle: "бизнестің барлық түрлері үшін жасалған",
			readMore: "Сұхбатты қарау",
			theme: "blueSky",
			businessType: [
				{
					type: BUSINESS_TYPES.COFFEE_SHOP,
					tabName: pagesLinksKz.businessTypes.coffeehouse.text,
					icon: CoffeeIcon,
					name: "Рауан Омар, \n«Korizza» кофеханасының негізін қалаушы",
					nameClass: componentStyles.businessName__kz,
					details:
						"Бизнесті дамыту үшін процестерді оңтайландыру\nжәне сатуды талдау үшін Quick Resto құралдарын пайдаланамыз.",
					detailsTablet:
						"Бизнесті дамыту үшін процестерді оңтайландыру және сатуды талдау үшін Quick Resto құралдарын пайдаланамыз.",
					detailsMobile:
						"Бизнесті дамыту үшін процестерді оңтайландыру және сатуды талдау үшін Quick Resto құралдарын пайдаланамыз.",
					photoCaption: "«Кофе адамдарды біріктіреді»",
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/korizza.png"
							alt="Логотип кофейни «Korizza»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={componentStyles.businessLogo__kz}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/korizza.jpeg"
							alt="Рауан Омар, основатель кофейни «Korizza»"
							// breakpoints={[424, 740, 920, 524]}
							// sizes="(max-width: 500px) 424px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 524px"
							objectFit="cover"
							placeholder="blurred"
							quality={100}
						/>
					),

				},
				{
					type: BUSINESS_TYPES.RESTAURANT,
					tabName: pagesLinksKz.businessTypes.restaurant.text,
					icon: RestaurantIcon,
					name: "Константин Кудобаев, \n«Сезоны» жекеменшік барының серіктесушісі",
					photoCaption: "«Өзімді ойынның кейіпкері болып сезінемін, күн сайын жаңа тапсырмалар»",
					bubbleType: BUBBLES_TYPES.SECONDARY,
					details: (
						<>
							Бірінші кезекте бізді интерфейстің ыңғайлылығы қызықтырды — бұл автоматтандыру жүйелері
							нарығында айфон деп айтуға болады.
						</>
					),
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/seasons.png"
							alt="Логотип ресторана «Сезоны»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo,
								componentStyles.businessMainImageCropSeasons
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/seasons.jpg"
							alt="Константин Кудобаев, сооснователь частного бара «Сезоны»"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					link: {
						href: `${externalLinks.quickrestoRU.href}secret/sezony/`,
						text: "Сұхбатты қарау",
						useGatsbyLink: false,
						target: '_blank'
					},
				},
				{
					type: BUSINESS_TYPES.BAR,
					tabName: pagesLinksKz.businessTypes.bar.text,
					icon: BarIcon,
					name:
						"Куаныш Нурахов, \n«Tang» изакая-барының серіктесушісі",
					photoCaption: (
						<div className={componentStyles.businessPhotoCaptionContent_tertiary}>
							«Кеспені жақсы <br/>
							көретіндер арасында <br/>
							жаман адамдар жоқ!»
						</div>
					),
					bubbleType: BUBBLES_TYPES.LINEAR,
					details: (
						<>
							Quick Resto барлық процестерді оңтайландырады және ақша қаражаттың қозғалысын бақылауға көмектеседі
						</>
					),
					detailsMobile: (
						<>
							Quick Resto барлық процестерді оңтайландырады және ақша қаражаттың қозғалысын бақылауға көмектеседі
						</>
					),
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/tang.png"
							alt="Логотип сети винотек и баров «Первый нос»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo__kz_bar
								// componentStyles.businessMainImageCropBar
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/tang.jpeg"
							alt="Куаныш Нурахов - сооснователь изакая-бар «Tang»"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					type: BUSINESS_TYPES.CAFE,
					tabName: pagesLinksKz.businessTypes.cafe.text,
					icon: CafeIcon,
					name: "Анастасия и Андрей, \n«О, мой Боул!» серіктесушілері",
					photoCaption: "«Қоғамдық тамақтану тек тамақ қана емес, бұл эмоциялар да»",
					bubbleType: BUBBLES_TYPES.QUATERNARY,
					details: (
						<>
							Біз қызметкерлерге бұрын бөлек адамды қажет ететін барлық процестерді тапсырдық.
						</>
					),
					detailsMobile: (
						<>
							Біз қызметкерлерге бұрын бөлек адамды қажет ететін барлық процестерді тапсырдық.
						</>
					),
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/oh-my-bowl.png"
							alt="Логотип Oh My Bowl"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo,
								componentStyles.businessMainImageCropOhMyBowl
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/oh-my-bowl.jpg"
							alt="Анастасия и Андрей, сооснователи Oh My Bowl"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					link: {
						href: `${externalLinks.quickrestoRU.href}secret/ohmybowl/`,
						text: "Сұхбатты қарау",
						useGatsbyLink: false,
						target: '_blank'
					},
				},
				{
					type: BUSINESS_TYPES.FOODTRACK,
					tabName: pagesLinksKz.businessTypes.foodtruck.text,
					icon: FoodtrackIcon,
					name: "Владимир Иванюгин, \n«Крошка Ли» фудтрагының иесі",
					details:
						"Егер планшетің бар болса, Quick Resto бағдарламасын жүктеп аласын да, оған позицияларды енгізіп, сауда жасайсың. Бұл үшін саған 2 сағат керек.",
					photoCaption: "«Бәріне пысықырып\nта қарамайсың да, өз бизнесіңді ашасың»",
					bubbleType: BUBBLES_TYPES.PYATERIC,
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/kroshka-li.png"
							alt="Логотип фудтрака «Крошка Ли»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo,
								componentStyles.businessMainImageCropKroshkaLi
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/kroshka-li.jpg"
							alt="Владимир Иванюгин, владелец фудтрака «Крошка Ли»"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					link: {
						href: `${externalLinks.quickrestoRU.href}secret/kroshka-li/`,
						text: "Сұхбатты қарау",
						useGatsbyLink: true,
						target: '_blank'
					},
				},
				{
					type: BUSINESS_TYPES.MULTI,
					tabName: pagesLinksKz.businessTypes.multiformats.text,
					icon: MultiformatIcon,
					name: "Максим Кравцов, \n«Вираж» велокофеханасының негізін қалаушы",
					details: (
						<>
							Кафеде бір ауысымда бір адам жұмыс істейді — ол бәрін жылдам жасап, басын артық есептеумен қатырмауы керек.
						</>
					),
					photoCaption:
						"«Неліктен велосипедшілерді өз кафелеріне\nжинамасқа?»",
					bubbleType: BUBBLES_TYPES.HEX,
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/virag.png"
							alt="Логотип велокофейни «Вираж»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo,
								componentStyles.businessMainImageCropVirag
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/virag.jpg"
							alt="Максим Кравцов, основатель велокофейни «Вираж»"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					link: {
						href: `${externalLinks.quickrestoRU.href}secret/virazh/`,
						text: "Сұхбатты қарау",
						useGatsbyLink: false,
						target: '_blank'
					},
				},
			],
		}
	}

	if (locale === 'ru-BY') {
		return {
			title: "Открывай новое. Сейчас!",
			subTitle: "создан для всех типов бизнеса",
			readMore: "Смотреть интервью",
			theme: "blueSky",
			businessType: [
				{
					type: BUSINESS_TYPES.COFFEE_SHOP,
					tabName: pagesLinks.businessTypes.coffeehouse.text,
					icon: CoffeeIcon,
					name: "Евгения Чева, \nосновательница кофейни «Щегол»",
					details:
						"Quick Resto решает половину моих задач, если честно. Для маленького проекта это очень важно...",
					detailsTablet:
						"Quick Resto решает половину моих задач, если честно. Для маленького проекта это очень важно.",
					detailsMobile:
						"Quick Resto решает половину моих задач, если честно. Для маленького проекта это очень важно.",
					photoCaption: "«Если ты чего-то боишься — это нормально»",
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/shegol.png"
							alt="Логотип кофейни «Щегол»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/shegol.jpg"
							alt="Евгения Чева, основательница кофейни «Щегол»"
							// breakpoints={[424, 740, 920, 524]}
							// sizes="(max-width: 500px) 424px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 524px"
							objectFit="cover"
							placeholder="blurred"
							quality={100}
						/>
					),
					link: {
						href: `${externalLinks.quickrestoRU.href}secret/shchegol/`,
						text: "Смотреть интервью",
						useGatsbyLink: true,
						target: '_blank'
					},
				},
				{
					type: BUSINESS_TYPES.RESTAURANT,
					tabName: pagesLinks.businessTypes.restaurant.text,
					icon: RestaurantIcon,
					name: "Константин Кудобаев, \nсооснователь частного бара «Сезоны»",
					photoCaption: "«Чувствую себя героем игры, каждый день новые задания»",
					bubbleType: BUBBLES_TYPES.SECONDARY,
					details: (
						<>
							В&nbsp;первую очередь нас привлекло удобство интерфейса&nbsp;&mdash;
							это&nbsp;айфон на&nbsp;рынке систем автоматизации.
						</>
					),
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/seasons.png"
							alt="Логотип ресторана «Сезоны»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo,
								componentStyles.businessMainImageCropSeasons
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/seasons.jpg"
							alt="Константин Кудобаев, сооснователь частного бара «Сезоны»"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					link: {
						href: `${externalLinks.quickrestoRU.href}secret/sezony/`,
						text: "Смотреть интервью",
						useGatsbyLink: true,
						target: '_blank'
					},
				},
				{
					type: BUSINESS_TYPES.BAR,
					tabName: pagesLinks.businessTypes.bar.text,
					icon: BarIcon,
					name:
						"Наталья и Надежда, сооснователи \nсети винотек и баров «Первый нос»",
					photoCaption: (
						<div className={componentStyles.businessPhotoCaptionContent_tertiary}>
							«Открытие первого заведения было авантюрой»
						</div>
					),
					bubbleType: BUBBLES_TYPES.TERTIARY,
					details: (
						<>
							Когда мы&nbsp;нашли Quick Resto, я&nbsp;так радовалась, потому что
							эта&nbsp;система учета как Iphone. Выглядит супер стильно!
						</>
					),
					detailsMobile: (
						<>
							Когда мы&nbsp;нашли Quick Resto, я&nbsp;так радовалась, потому что эта
							система учета как Iphone. Выглядит супер стильно!
						</>
					),
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/bar.png"
							alt="Логотип сети винотек и баров «Первый нос»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo
								// componentStyles.businessMainImageCropBar
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/bar.jpg"
							alt="Наталья и Надежда, сооснователи сети винотек и баров «Первый нос»"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					link: {
						href: `${externalLinks.quickrestoRU.href}secret/pervyj-nos/`,
						text: "Смотреть интервью",
						useGatsbyLink: true,
						target: '_blank'
					},
				},
				{
					type: BUSINESS_TYPES.CAFE,
					tabName: pagesLinks.businessTypes.cafe.text,
					icon: CafeIcon,
					name: "Анастасия и Андрей, \nсооснователи «О, мой Боул!»",
					photoCaption: "«Общепит не только про еду, но и про эмоции»",
					bubbleType: BUBBLES_TYPES.QUATERNARY,
					details: (
						<>
							Мы&nbsp;делегировали сотрудникам все процессы, на&nbsp;которые
							нам&nbsp;раньше требовался отдельный человек.
						</>
					),
					detailsMobile: (
						<>
							Мы&nbsp;делегировали сотрудникам все процессы, на&nbsp;которые нам
							раньше требовался отдельный человек.
						</>
					),
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/oh-my-bowl.png"
							alt="Логотип Oh My Bowl"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo,
								componentStyles.businessMainImageCropOhMyBowl
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/oh-my-bowl.jpg"
							alt="Анастасия и Андрей, сооснователи Oh My Bowl"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					link: {
						href: `${externalLinks.quickrestoRU.href}secret/ohmybowl/`,
						text: "Смотреть интервью",
						useGatsbyLink: true,
						target: "_blank"
					},
				},
				{
					type: BUSINESS_TYPES.FOODTRACK,
					tabName: pagesLinks.businessTypes.foodtruck.text,
					icon: FoodtrackIcon,
					name: "Владимир Иванюгин, \nвладелец фудтрака «Крошка Ли»",
					details:
						"Если у тебя есть планшет, ты скачиваешь программу Quick Resto, заводишь туда позиции и торгуешь. Для этого тебе нужно 2 часа.",
					photoCaption: "«Плюёшь на всё и открываешь свой бизнес»",
					bubbleType: BUBBLES_TYPES.PYATERIC,
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/kroshka-li.png"
							alt="Логотип фудтрака «Крошка Ли»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo,
								componentStyles.businessMainImageCropKroshkaLi
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/kroshka-li.jpg"
							alt="Владимир Иванюгин, владелец фудтрака «Крошка Ли»"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					link: {
						href: `${externalLinks.quickrestoRU.href}secret/kroshka-li/`,
						text: "Смотреть интервью",
						useGatsbyLink: true,
						target: "_blank"
					},
				},
				{
					type: BUSINESS_TYPES.MULTI,
					tabName: pagesLinks.businessTypes.multiformats.text,
					icon: MultiformatIcon,
					name: "Максим Кравцов, \nоснователь велокофейни «Вираж»",
					details: (
						<>
							В&nbsp;кафе в&nbsp;смене работает один человек&nbsp;&mdash; ему нужно
							всё делать быстро и&nbsp;не&nbsp;занимать голову лишними расчётами.
						</>
					),
					photoCaption:
						"«А почему не собирать велосипедистов в собственном \nкафе?»",
					bubbleType: BUBBLES_TYPES.HEX,
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/virag.png"
							alt="Логотип велокофейни «Вираж»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo,
								componentStyles.businessMainImageCropVirag
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/virag.jpg"
							alt="Максим Кравцов, основатель велокофейни «Вираж»"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					link: {
						href: `${externalLinks.quickrestoRU.href}secret/virazh/`,
						text: "Смотреть интервью",
						useGatsbyLink: true,
						target: '_blank'
					},
				},
			],
		}
	}

	return {
		title: "Открывай новое. Сейчас!",
		subTitle: "создан для всех типов бизнеса",
		readMore: "Смотреть интервью",
		theme: "blueSky",
		businessType: [
			{
				type: BUSINESS_TYPES.COFFEE_SHOP,
				tabName: pagesLinks.businessTypes.coffeehouse.text,
				icon: CoffeeIcon,
				name: "Евгения Чева, \nосновательница кофейни «Щегол»",
				details:
					"Quick Resto решает половину моих задач, если честно. Для маленького проекта это очень важно.",
				detailsTablet:
					"Quick Resto решает половину моих задач, если честно. Для маленького проекта это очень важно.",
				detailsMobile:
					"Quick Resto решает половину моих задач, если честно. Для маленького проекта это очень важно.",
				photoCaption: "«Если ты чего-то боишься — это нормально»",
				logo: (
					<StaticImage
						className={componentStyles.businessLogo}
						src="../../../components/_V2/BusinessTypes/assets/images/logo/shegol.png"
						alt="Логотип кофейни «Щегол»"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				photo: (
					<StaticImage
						className={componentStyles.businessLogo}
						src="../../../components/_V2/BusinessTypes/assets/images/persons/shegol.jpg"
						alt="Евгения Чева, основательница кофейни «Щегол»"
						// breakpoints={[424, 740, 920, 524]}
						// sizes="(max-width: 500px) 424px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 524px"
						objectFit="cover"
						placeholder="blurred"
						quality={100}
					/>
				),
				link: {
					href: "/secret/shchegol/",
					text: "Смотреть интервью",
					useGatsbyLink: true,
				},
			},
			{
				type: BUSINESS_TYPES.RESTAURANT,
				tabName: pagesLinks.businessTypes.restaurant.text,
				icon: RestaurantIcon,
				name: "Константин Кудобаев, \nсооснователь частного бара «Сезоны»",
				photoCaption: "«Чувствую себя героем игры, каждый день новые задания»",
				bubbleType: BUBBLES_TYPES.SECONDARY,
				details: (
					<>
						В&nbsp;первую очередь нас привлекло удобство интерфейса&nbsp;&mdash;
						это&nbsp;айфон на&nbsp;рынке систем автоматизации.
					</>
				),
				logo: (
					<StaticImage
						className={componentStyles.businessLogo}
						src="../../../components/_V2/BusinessTypes/assets/images/logo/seasons.png"
						alt="Логотип ресторана «Сезоны»"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				photo: (
					<StaticImage
						className={classNames(
							componentStyles.businessLogo,
							componentStyles.businessMainImageCropSeasons
						)}
						src="../../../components/_V2/BusinessTypes/assets/images/persons/seasons.jpg"
						alt="Константин Кудобаев, сооснователь частного бара «Сезоны»"
						objectFit="cover"
						placeholder="blurred"
						quality={90}
					/>
				),
				link: {
					href: "/secret/sezony/",
					text: "Смотреть интервью",
					useGatsbyLink: true,
				},
			},
			{
				type: BUSINESS_TYPES.BAR,
				tabName: pagesLinks.businessTypes.bar.text,
				icon: BarIcon,
				name:
					"Наталья и Надежда, сооснователи \nсети винотек и баров «Первый нос»",
				photoCaption: (
					<div className={componentStyles.businessPhotoCaptionContent_tertiary}>
						«Открытие первого заведения было авантюрой»
					</div>
				),
				bubbleType: BUBBLES_TYPES.TERTIARY,
				details: (
					<>
						Когда мы&nbsp;нашли Quick Resto, я&nbsp;так радовалась, потому что
						эта&nbsp;система учета как Iphone. Выглядит супер стильно!
					</>
				),
				detailsMobile: (
					<>
						Когда мы&nbsp;нашли Quick Resto, я&nbsp;так радовалась, потому что эта
						система учета как Iphone. Выглядит супер стильно!
					</>
				),
				logo: (
					<StaticImage
						className={componentStyles.businessLogo}
						src="../../../components/_V2/BusinessTypes/assets/images/logo/bar.png"
						alt="Логотип сети винотек и баров «Первый нос»"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				photo: (
					<StaticImage
						className={classNames(
							componentStyles.businessLogo
							// componentStyles.businessMainImageCropBar
						)}
						src="../../../components/_V2/BusinessTypes/assets/images/persons/bar.jpg"
						alt="Наталья и Надежда, сооснователи сети винотек и баров «Первый нос»"
						objectFit="cover"
						placeholder="blurred"
						quality={90}
					/>
				),
				link: {
					href: "/secret/pervyj-nos/",
					text: "Смотреть интервью",
					useGatsbyLink: true,
				},
			},
			{
				type: BUSINESS_TYPES.CAFE,
				tabName: pagesLinks.businessTypes.cafe.text,
				icon: CafeIcon,
				name: "Анастасия и Андрей, \nсооснователи «О, мой Боул!»",
				photoCaption: "«Общепит не только про еду, но и про эмоции»",
				bubbleType: BUBBLES_TYPES.QUATERNARY,
				details: (
					<>
						Мы&nbsp;делегировали сотрудникам все процессы, на&nbsp;которые
						нам&nbsp;раньше требовался отдельный человек.
					</>
				),
				detailsMobile: (
					<>
						Мы&nbsp;делегировали сотрудникам все процессы, на&nbsp;которые нам
						раньше требовался отдельный человек.
					</>
				),
				logo: (
					<StaticImage
						className={componentStyles.businessLogo}
						src="../../../components/_V2/BusinessTypes/assets/images/logo/oh-my-bowl.png"
						alt="Логотип Oh My Bowl"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				photo: (
					<StaticImage
						className={classNames(
							componentStyles.businessLogo,
							componentStyles.businessMainImageCropOhMyBowl
						)}
						src="../../../components/_V2/BusinessTypes/assets/images/persons/oh-my-bowl.jpg"
						alt="Анастасия и Андрей, сооснователи Oh My Bowl"
						objectFit="cover"
						placeholder="blurred"
						quality={90}
					/>
				),
				link: {
					href: "/secret/ohmybowl/",
					text: "Смотреть интервью",
					useGatsbyLink: true,
				},
			},
			{
				type: BUSINESS_TYPES.FOODTRACK,
				tabName: pagesLinks.businessTypes.foodtruck.text,
				icon: FoodtrackIcon,
				name: "Владимир Иванюгин, \nвладелец фудтрака «Крошка Ли»",
				details:
					"Если у тебя есть планшет, ты скачиваешь программу Quick Resto, заводишь туда позиции и торгуешь. Для этого тебе нужно 2 часа.",
				photoCaption: "«Плюёшь на всё и открываешь свой бизнес»",
				bubbleType: BUBBLES_TYPES.PYATERIC,
				logo: (
					<StaticImage
						className={componentStyles.businessLogo}
						src="../../../components/_V2/BusinessTypes/assets/images/logo/kroshka-li.png"
						alt="Логотип фудтрака «Крошка Ли»"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				photo: (
					<StaticImage
						className={classNames(
							componentStyles.businessLogo,
							componentStyles.businessMainImageCropKroshkaLi
						)}
						src="../../../components/_V2/BusinessTypes/assets/images/persons/kroshka-li.jpg"
						alt="Владимир Иванюгин, владелец фудтрака «Крошка Ли»"
						objectFit="cover"
						placeholder="blurred"
						quality={90}
					/>
				),
				link: {
					href: "/secret/kroshka-li/",
					text: "Смотреть интервью",
					useGatsbyLink: true,
				},
			},
			{
				type: BUSINESS_TYPES.MULTI,
				tabName: pagesLinks.businessTypes.multiformats.text,
				icon: MultiformatIcon,
				name: "Максим Кравцов, \nоснователь велокофейни «Вираж»",
				details: (
					<>
						В&nbsp;кафе в&nbsp;смене работает один человек&nbsp;&mdash; ему нужно
						всё делать быстро и&nbsp;не&nbsp;занимать голову лишними расчётами.
					</>
				),
				photoCaption:
					"«А почему не собирать велосипедистов в собственном \nкафе?»",
				bubbleType: BUBBLES_TYPES.HEX,
				logo: (
					<StaticImage
						className={componentStyles.businessLogo}
						src="../../../components/_V2/BusinessTypes/assets/images/logo/virag.png"
						alt="Логотип велокофейни «Вираж»"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				photo: (
					<StaticImage
						className={classNames(
							componentStyles.businessLogo,
							componentStyles.businessMainImageCropVirag
						)}
						src="../../../components/_V2/BusinessTypes/assets/images/persons/virag.jpg"
						alt="Максим Кравцов, основатель велокофейни «Вираж»"
						objectFit="cover"
						placeholder="blurred"
						quality={90}
					/>
				),
				link: {
					href: "/secret/virazh/",
					text: "Смотреть интервью",
					useGatsbyLink: true,
				},
			},
		],
	}
}
